@import "../common.scss";

  .aboutSectionContainer {
    @include flexColumnStartContainer;
  }

  .sectionContent {
    text-align: justify;
  }

 @media (width > 1024px) {
   .aboutSectionContainer {
     @include flexColumnStartContainer;
     margin-bottom: 8rem;
     padding: var(--default-container-padding);

     font-size: 18px;

     ::selection {
       background-color: var(--secondary);
       color: var(--primary)
     }

     b {
       &:hover {
         color: var(--action);
         cursor: var(--default-cursor);
       }
     }
   }
 }
