@import '../common.scss';

.projectSectionContainer {
  @include flexColumnStartContainer;
}

@media (width < 1024px) {
  .projectContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--default-container-gap);

    &__img {
      grid-area: 1 / 1 / 2 / 2;
    }

    &__content {
      @include flexColumnStartContainer;
      grid-area: 2 / 1 / 2 / 3;

      &--title {
        @include flexRowStartContainer;
      }
    }
  }
}

@media (width > 1024px) {
  .projectContainer {
    display: grid;
    grid-template-columns: 30% 1fr;
    grid-template-rows: 1fr;
    padding: 1rem;

    &__img {
      @include flexColumnStartContainer;
      margin-right: 2rem;
    }

    &__content {
      @include flexColumnStartContainer;

      &--title {
        @include flexRowStartContainer;
        gap: 0.5rem;
      }
    }

    &:hover {
      background-color: var(--primaryLight);
      border-radius: var(--default-border-radius);
      cursor: var(--default-cursor);
    }

    :hover > .projectContainer__content--title {
      color: var(--action);
    }
  }
}