:root {
  font-family: 'Lato', 'sans-serif';
  background-color: var(--primary);
  color: var(--textColor);

  --default-container-gap: 2rem;
  --default-container-margin: 5rem;
  --default-border-radius: .5rem;
  --default-cursor: pointer;
  --default-container-padding: 1rem;

  @media (width < 1024px ) {
    --icon-fontSize: 1.7rem;
  }
  --icon-fontSize: 2.5rem;

  --small-fontSize: .75rem;

  /* Colors */
  --primary: #001524;
  --primaryLight: #003153;
  --primaryDark: #000000;

  --secondary: #D6CC99;
  --secondaryLight: #eae5cc;
  --secondaryDark: #cbbf7f;

  --action: #92FAE6;

  --textColor: #d9e1e1;
}

@mixin flexColumnStartContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  gap: var(--default-container-gap);

}

@mixin flexRowStartContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  gap: var(--default-container-gap);

}