body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--secondary);
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  }
}

html,body {
  scrollbar-color: var(--secondary) var(--primary)
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  margin: unset;
  padding: unset;
}

p {
  margin: unset;
  padding: unset;
}

b {
  font-weight: 900;
}

a {
  color: unset;
  text-decoration: unset;
}

img {
    max-width: 100%;
    max-height: 100%;
    border-radius: var(--default-border-radius);
    border: 1px solid var(--primaryLight);
}

button { 
  border: none;
}
