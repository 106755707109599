@import './common.scss';

.navLinkContainer {
  display: none;
}

@media (width < 1024px) {
  .mainContainer {
    @include flexColumnStartContainer;

    @media (width < 1024px) and (width > 768px) {
      margin-left: 10rem;
      margin-right: 10rem;
    }
    margin: 4rem 2rem 4rem 2rem;

    &__contentArea {
      @include flexColumnStartContainer;
      gap: 5rem
    }
  }

  .stickyContainer__content--iconLinks {
    margin-top: 2rem;
    @include flexRowStartContainer;
    gap: 1rem;
  }

  .stickyContainer__content--switches {
    display: none;
  }
}

@media (width > 1024px) {
  .mainContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);

    @media (width > 1024px) and (width < 1440px) {
      margin-left: 10rem;
      margin-right: 10rem;
    }

    margin-left: 25rem;
    margin-right: 25rem;

    gap: var(--default-container-gap);

    &__contentArea {
      grid-area: 1 / 2 / 6 / 3;
      @include flexColumnStartContainer;
      gap: 10rem;

      margin-top: var(--default-container-margin);
      margin-bottom: var(--default-container-margin);
    }
  }

  .stickyContainer {
    grid-area: 1 / 1 / 6 / 1;

    @include flexColumnStartContainer;
    height: 100vh;

    /* Make Flexbox container sticky */
    position: sticky;
    align-self: flex-start;
    top: 0;

    &__content {
      @include flexColumnStartContainer;

      height:100%;

      margin-top: var(--default-container-margin);;
      margin-bottom: var(--default-container-margin);;

      &--navLinks {
        @include flexColumnStartContainer;
        margin-top: 3rem;
        cursor: var(--default-cursor);
      }

      &--iconLinks {
        @include flexRowStartContainer;
        cursor: var(--default-cursor)
      }

      &--switches {
        margin-top: auto;
        @include flexColumnStartContainer;
      }
    }
  }
  .navLinkContainer {
    @include flexRowStartContainer;
    align-items: center;
    gap: .8rem;

    &__bar {
      width: 25px;
      height: 1px;
      background-color: var(--textColor);
      transition: width 1s;

      &--active {
        @extend .navLinkContainer__bar;
        background-color: var(--secondary);
        width: 60px;
      }
    }

    &:hover > .navLinkContainer__bar {
      background-color: var(--secondary);
      width: 60px;
    }

    &:hover {
      color: var(--secondary);
    }
  }
}

.activeNavLink {
  color: var(--secondary)
}

.iconButton {
  color: var(--secondary);

  &:hover {
    color: var(--secondaryLight);
  }
}

.fade {
  transition: opacity 0.5s
}