@import '../common.scss';


@media (width < 1024px) {
  .titleContainer {
    @include flexColumnStartContainer;
    gap: 1rem;

  }

}

@media (width > 1024px) {
  .titleContainer {
    @include flexColumnStartContainer;

    p {
      font-size: 18px;
    }

    ::selection {
      background-color: var(--secondary);
      color: var(--primary)
    }
  }
}

