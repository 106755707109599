@import "../common.scss";

@media (width < 1024px) {
  .experienceContainer {
    @include flexColumnStartContainer;
    gap: .2rem;

    &__content {
      @include flexColumnStartContainer
    }

    &__time {
      font-size: var(--small-fontSize);
    }
  }

  .mobileHeader {
    position: sticky;
    top: 0px;
    width: 100%;

    &.scrolled {
      @media (width < 1024px) and (width > 768px) {
        width: calc(100% + 2 * 10rem);
        margin-left: -10rem;
      }

      width: calc(100% + 2 * 2rem);
      margin-left: -2rem;
      background-color: var(--primaryLight);
      opacity: 0.8;

      @include flexRowStartContainer;
      align-items: center;
      height: 50px;

      transition: width 1s, background-color 1s, opacity 1s;

      h2 {
        @media (width < 1024px) and (width > 768px) {
          margin-left: 10rem !important
        }
        margin-left: 2rem !important
      }
    }
  }
}

@media (width > 1024px) {
  .experienceContainer {
    display: grid;
    grid-template-columns: 30% 1fr;
    grid-template-rows: 1fr;
    padding: var(--default-container-padding);

    &__time {
      @include flexColumnStartContainer
    }

    &__content {
      @include flexColumnStartContainer
    }
  }

  .mobileHeader {
    display: none;
  }
}

.experienceSectionContainer {
  @include flexColumnStartContainer;

  &__link {
    @include flexRowStartContainer;
    align-items: center;
    gap: .5rem;

    cursor: var(--default-cursor);

    &--icon {
      transition: transform 1s ease;

      &:hover {
        transform: translateX(10px);
        color: var(--secondary)
      }
    }

    &:hover > .experienceSectionContainer__link--icon {
      transform: translateX(10px);
      color: var(--secondary)
    }

    &:hover {
      text-decoration: underline var(--secondary);
    }
  }
}

.singleTagContainer {
  @include flexRowStartContainer;
  gap: .8rem;
  align-items: center;
  flex-wrap: wrap;

  &__tag {
    border-radius: 1rem;
    padding: .5rem;
    background-color: var(--action);
    color: var(--primaryDark);
    font-weight: bold;
    white-space: nowrap;
    font-size: var(--small-fontSize)
  }
}

